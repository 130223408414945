import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import ResourceTable from '../../components/ResourceTable/ResourceTable';

class TrainingResources extends Component {
  render() {
    const { data } = this.props;
    const { edges: resources } = data.allTrainingResourcesListJson;
    const mappedResources = resources.map(resource => resource.node);

    return (
      <Layout>
        <Helmet
          title="VEYM | Training Resources"
          meta={[
            {
              name: 'description',
              content: 'Tài Liệu Huấn Luyện - VEYM Official Training Resources',
            },
          ]}
        />
        <PageHeading>Training Resources</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/resources">Resources</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Training Resources</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <Container>
          <br />
          <h3>Tài Liệu Huấn Luyện</h3>
          <br />
          <ResourceTable resources={mappedResources} />
        </Container>
      </Layout>
    );
  }
}

export default TrainingResources;

export const pageQuery = graphql`
  query TrainingResourcesQuery {
    allTrainingResourcesListJson {
      edges {
        node {
          name
          type
          links {
            fileType
            fileLink
          }
          categoryStyle {
            background
            border
          }
          categoryTextStyle {
            color
          }
          updated
        }
      }
    }
  }
`;

export const frontmatter = {
  title: 'Training Resources',
  category: 'resources',
  path: '/resources/trainingresources',
  description: 'Training Resources',
  tags: 'resource, tai lieu huan luyen, ht, cap, hlv, huan luyen vien,  ',
};
